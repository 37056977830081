<template>
  <transition name="slide-in">
    <div v-show="showFlag" class="msg-login">
      <h :iscancel="true" @cancel="hide">
        <span class="name" v-text="name" />
      </h>
      <div class="text-list">
        <p>会员特权说明：</p>
        <p>1、随时随地点播最新解说视频</p>
        <p>2、观看斗牌TV独家版权大赛直播</p>
        <p>3、享受超清画质</p>
        <p>4、尊贵会员标示</p>
      </div>
    </div>
  </transition>
</template>
<script>
import h from "@/components/base/header";
export default {
  props: {
    name: {
      type: String,
      default: ""
    }
  },
  components: {
    h
  },
  data() {
    return {
      showFlag: false
    };
  },
  methods: {
    show() {
      this.showFlag = true;
    },
    hide() {
      this.showFlag = false;
    }
  }
};
</script>
<style lang="stylus" scoped>
@import "~assets/stylus/mixin.styl";
.msg-login
  position fixed
  top 0
  right 0
  bottom 0
  left 0
  background #fff
  z-index 11
  .text-list
    margin 10px 15px 0
    font-size 14px
    line-height 1.5
</style>
