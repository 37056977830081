<template>
  <transition name="slide">
    <div class="vip-container">
      <h :isback="true">
        <span class="name">会员中心</span>
        <span class="question" @click="showVipPower">?</span>
      </h>
      <div class="main">
        <ul class="list">
          <li
            v-for="(item, inx) in list"
            :key="item.key"
            :class="{ active: inx == active }"
            @click="changeActive(inx)"
          >
            <div class="inner">
              <div class="name" v-text="item.title" />
              <div class="price" v-text="`${item.price}金币`" />
            </div>
            <i class="cubeic-ok ok" />
          </li>
        </ul>
        <div class="pay-money border-top-1px border-bottom-1px">
          兑换金额：<span v-if="list.length">{{ list[active].price }}金币</span>
        </div>
        <div class="pay-type">
          <span>金币余额：{{ user.gold }}</span>
        </div>
        <!-- <div class="pay-type">
          <span>支付方式：</span><img src="./img/alipay.png" width="62" />
        </div> -->
        <div class="pay-tips">
          兑换成功后将会收到通知，预计会有2分钟的延迟！
        </div>
        <div class="btn">
          <cube-button @click="pay">立即兑换</cube-button>
        </div>
        <!-- <user-pay ref="pay" name="会员兑换" :code="code" :price="price" /> -->
        <vip-power ref="power" name="会员特权" />
      </div>
    </div>
  </transition>
</template>
<script>
import h from "@/components/base/header";
// import UserPay from "@/components/user/user-pay";
import VipPower from "@/components/user/vip-power";
import { getVipList, goldRechargeMember } from "@/api/recharge";
import { mapState, mapMutations } from "vuex";
export default {
  components: {
    h,
    // UserPay,
    VipPower
  },
  data() {
    return {
      list: [],
      active: 0,
      code: "",
      price: 0
    };
  },
  computed: {
    ...mapState({
      user: state => state.user.info
    })
  },
  created() {
    this._getVipList();
  },
  methods: {
    ...mapMutations({
      toast: "global/SET_TOAST"
    }),
    _getVipList() {
      getVipList().then(res => {
        this.list = res.object;
        this.price = this.list[this.active].price;
      });
    },
    changeActive(inx) {
      this.active = inx;
      this.price = this.list[this.active].price;
    },
    pay() {
      if (this.user.gold < this.price) {
        this.toast("金币不足");
        return;
      }
      const toast = this.$createToast({
        txt: "Loading...",
        mask: true
      });
      toast.show();
      const params = {
        type: this.list[this.active].type
      };
      goldRechargeMember(params).then(res => {
        if (!res) {
          return;
        }
        const toast1 = this.$createToast({
          txt: "兑换成功",
          type: "txt",
          time: 1000
        });
        toast.hide();
        toast1.show();
      });
    },
    showVipPower() {
      this.$refs.power.show();
    }
  }
};
</script>
<style lang="stylus" scoped>
@import "~assets/stylus/mixin.styl";
.vip-container
  position fixed
  top 0
  right 0
  bottom 0
  left 0
  background #fff
  font-size 14px
  color #383A40
  overflow hidden
  z-index 10
  .main
    margin 20px 14px 0
  .list
    display flex
    flex-wrap wrap
    li
      position relative
      display flex
      align-items center
      flex 0 0 110px
      height 69px
      border 1px solid #f0f0f0
      border-radius 4px
      margin 0 8.5px 12px 0
      &:nth-of-type(3n+0)
        margin-right 0
      &.active
        border-color #38CB4D
        .ok
          opacity 1
      .inner
        display inline-block
        width 100%
        font-size 14px
        text-align center
        .name
          color #1E88E4
        .price
          color #6B6B6B
      .ok
        position absolute
        right 0
        bottom 0
        display inline-block
        color #fff
        opacity 0
        &:after
          position absolute
          right 0
          bottom 0
          display block
          content ""
          border-width 24px 0 24px 24px
          border-style solid
          border-color transparent transparent #38CB4D
        &:before
          position relative
          z-index 1
  .pay-money
    line-height 38px
    span
      font-weight bold
      color #38CB4D
  .pay-type
    margin 23px 0 47px
    img,span
      display inline-block
      vertical-align middle
  .pay-tips
    margin-bottom 8px
    font-size 11px
    color #F6210B
    text-align center
  .btn
    margin 0 30px
    .cube-btn
      border-radius 25px
      background linear-gradient(to right, #1A62DB, #2A9BFB)
      color #fff
</style>
